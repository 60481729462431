import { encryptData, decryptData } from './encryptionUtils';

const STORAGE_KEY = 'PSYCHOLOGIST';

export const saveState = (state) => {
    try {
        const encryptedState = encryptData(state);
        localStorage.setItem(STORAGE_KEY, encryptedState);
    } catch (error) {
        console.error('Error saving state:', error);
    }
};

export const loadState = () => {
    try {
        const encryptedState = localStorage.getItem(STORAGE_KEY);
        if (!encryptedState) {
            return undefined;
        }

        return decryptData(encryptedState);
    } catch (error) {
        console.error('Error loading state:', error);
        return undefined;
    }
};
