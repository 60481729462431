const initialState = {
    loading: false,
    assessments: [],
};

const Assess = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_ASSESS_REQUEST':
            return { ...state, loading: true };
        case 'GET_ASSESS_SUCCESS':
            return { ...state, loading: false, assessments: action.assessments };
        case 'GET_ASSESS_FAILURE':
            return { ...state, loading: false, error: action.error };
        case 'ADD_ASSESSMENT_REQUEST':
            return { ...state, loading: true };
        case 'ADD_ASSESSMENT_SUCCESS':
            return { ...state, loading: false, assessments: [...state.assessments, action.assessment] };
        case 'ADD_ASSESSMENT_FAILURE':
            return { ...state, loading: false, error: action.error };
        case 'GET_ASSESSMENTS_REQUEST':
            return { ...state, loading: true };
        case 'GET_ASSESSMENTS_SUCCESS':
            return { ...state, loading: false, assessments: action.assessments };
        case 'GET_ASSESSMENTS_FAILURE':
            return { ...state, loading: false, error: action.error };
        case 'UPDATE_ASSESSMENT_REQUEST':
            return { ...state, loading: true };
        case 'UPDATE_ASSESSMENT_SUCCESS':
            return { ...state, loading: false, assessments: state.assessments?.map(assessment => assessment._id === action.assessment._id ? action.assessment : assessment) };
        case 'UPDATE_ASSESSMENT_FAILURE':
            return { ...state, loading: false, error: action.error };
        case 'DELETE_ASSESSMENT_REQUEST':
            return { ...state, loading: true };
        case 'DELETE_ASSESSMENT_SUCCESS':
            return { ...state, loading: false, assessments: state.assessments?.filter(assessment => assessment._id !== action.id) };
        case 'DELETE_ASSESSMENT_FAILURE':
            return { ...state, loading: false, error: action.error };
        case 'APPROVE_SEND_REQUEST':
            return { ...state, loading: true };
        case 'APPROVE_SEND_SUCCESS':
            return { ...state, loading: false, assessments: state.assessments?.map(assessment => assessment._id === action.assessment._id ? action.assessment : assessment) };
        case 'APPROVE_SEND_FAILURE':
            return { ...state, loading: false, error: action.error };
        case 'UPDATE_CONTENT_REQUEST':
            return { ...state, loading: true };
        case 'UPDATE_CONTENT_SUCCESS':
            return { ...state, loading: false, assessments: state.assessments?.map(assessment => assessment._id === action.assessment._id ? action.assessment : assessment) };
        case 'UPDATE_CONTENT_FAILURE':
            return { ...state, loading: false, error: action.error };
        default:
            return state;
    }
};

export default Assess;