import React from 'react'
import { useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom'
import { toast } from 'react-hot-toast';
import { verifyUser } from '../app/actions/authActions';
import { useSelector, useDispatch } from 'react-redux';


const VerifyUser = () => {
    const redirect = useNavigate();
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams(); // eslint-disable-line
    const token = searchParams.get("token");
    const { loading, isAuthenticated } = useSelector(state => state.Auth)


    useEffect(() => {
        if (isAuthenticated) {
            toast.success('User Verified Successfully!')
            redirect('/dashboard')
            window.location.reload()
        }
    }, [isAuthenticated]) // eslint-disable-line


    useEffect(() => {
        if (token) {
            dispatch(verifyUser(token))
        }
    }, [token])


    return (
        <div>
            {loading && <div className="loader">Loading...</div>}
        </div>
    )
}

export default VerifyUser