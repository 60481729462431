import React from 'react';
import Modal from 'react-modal';

const DeleteModal = ({ isOpen, onClose, onDelete }) => {
    return (
        <Modal isOpen={isOpen} onRequestClose={onClose} style={{
            overlay: { backgroundColor: 'rgba(0, 0, 0, 0.5)' },
            content: {
                width: 'max-content',
                margin: 'auto',
                height: 'max-content',
                padding: '20px',
                borderRadius: '10px',
                transition: 'all 0.3s ease-in-out'
            }
        }}>
            <h2 className='text-center'>Continue With Bronze Plan!</h2>
            <p className='text-center'>Please click confirm button below to continue with bronze</p>
            <div className='divider'></div>
            <div className='d-flex justify-content-center'>
                <button className='confirm-modal-btn mx-1' onClick={onDelete}>Confirm</button>
                <button className='cancel-modal-btn mx-1' onClick={onClose}>Cancel</button>
            </div>
        </Modal>
    );
};

export default DeleteModal;
