import { combineReducers } from 'redux';
import Auth from './authReducer';
import Form from './formReducer';
import Assess from './assessReducer';

const rootReducer = combineReducers({
    Auth,
    Form,
    Assess
});

export default rootReducer;