import React from 'react'
import { Link } from 'react-router-dom'
import './Page404.css'


const Home = () => {
    return (
        <div className="main-container-wrapper">
            <main>
                <div className="message-block" style={{
                    width: "715px",
                    gap: "6rem",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}>
                    <div className="left message-icon-wrapper">
                        <svg height="230px" width="230px" viewBox="0 0 197.143 197.143" xmlns="http://www.w3.org/2000/svg">
                            <g>
                                <g>
                                    <path style={{ fill: "red" }} d="M195.031,166.074l-85.592-148.24c-2.226-3.89-6.403-6.306-10.89-6.306
			c-4.477,0-8.65,2.412-10.894,6.292L1.68,166.747c-2.24,3.876-2.24,8.689,0,12.565c2.24,3.887,6.413,6.302,10.887,6.302h172.01
			c6.929,0,12.565-5.644,12.565-12.58C197.143,170.447,196.377,167.956,195.031,166.074z M184.577,178.324H12.571
			c-1.882,0-3.643-1.009-4.585-2.645c-0.945-1.636-0.948-3.665,0-5.3L93.961,21.456c0.941-1.628,2.698-2.645,4.588-2.645
			c1.882,0,3.654,1.016,4.592,2.645l85.764,148.537c0.626,0.895,0.966,1.943,0.966,3.046
			C189.871,175.952,187.491,178.324,184.577,178.324z"/>
                                    <polygon style={{ fill: "red" }} points="102.504,134.938 104.486,67.255 89.709,67.255 91.681,134.938 		" />
                                    <path style={{ fill: "red" }} d="M97.096,144.637c-5.146,0-8.879,3.905-8.879,9.28c0,5.39,3.733,9.294,8.879,9.294
			c5.229,0,8.886-3.815,8.886-9.294C105.982,148.452,102.328,144.637,97.096,144.637z"/>
                                </g>
                            </g>
                        </svg>
                    </div>
                    <div className="right message-block__content">
                        <span className="message-block__main-text" style={{ fontSize: "30px", color: "red" }}>Restricted Access</span>
                        <p className="message-block__text">You can't access this page directly.</p>
                        <a href="https://web-stage.cliniscripts.com/" className="message-block__link" title="Go back">
                            <span className="left message-block__link-icon">
                                <svg width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.17057 1.11022L0.611483 5.88295C0.338756 6.14658 0.202393 6.50567 0.202393 6.90113C0.202393 7.29658 0.338756 7.65113 0.611483 7.91931L5.17057 12.7102C5.71148 13.2784 6.5933 13.2784 7.13421 12.7102C7.67512 12.142 7.67512 11.2239 7.13421 10.6557L4.94785 8.36476H14.8115C15.5797 8.36476 16.2024 7.71476 16.2024 6.91022C16.2024 6.10567 15.5797 5.45567 14.8115 5.45567H4.94785L7.13876 3.16476C7.67967 2.59658 7.67967 1.6784 7.13876 1.11022C6.5933 0.542037 5.71603 0.542037 5.17057 1.11022Z" />
                                </svg>
                            </span>
                            <span className="left message-block__link-text">Go back</span>
                        </a>
                    </div>
                </div>
            </main>
        </div>
    )
}

export default Home