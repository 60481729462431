import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useLocation } from "react-router-dom";

const CompletedReportDetails = () => {
  const [forms, setForms] = useState([]);
  const [selectedForm, setSelectedForm] = useState("");
  const [formResponses, setFormResponses] = useState([]);
  const [finalText, setFinalText] = useState("");
  const [finalScore, setFinalScore] = useState("");
  const [finalAdditionalScore, setFinalAdditionalScore] = useState({});
  const [dateOfAssignment, setDateOfAssignment] = useState("");
  const location = useLocation();
  const row = location.state?.row;

  useEffect(() => {
    const fetchForms = async () => {
      const assessmentId = location.state?.row.id;

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_HOST}/forms/assessment/${assessmentId}/responses`
        );

        setForms(response.data);
      } catch (error) {
        console.error("Error fetching the forms:", error);
      }
    };

    fetchForms();
  }, [location.state?.row.id]);

  useEffect(() => {
    const fetchFormResponses = async () => {
      if (selectedForm) {
        const assessmentId = location.state?.row.id;
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_HOST}/forms/assessment/${assessmentId}/form/${selectedForm}/responses`
          );
          setFormResponses(response.data);
          if (
            response.data[0].finalText === undefined ||
            response.data[0].finalText === null ||
            response.data[0].finalText === ""
          ) {
            setFinalText("N/A");
          } else {
            setFinalText(response.data[0].finalText);
          }

          console.log(response.data[0]);

          if (
            response.data[0].finalScore === undefined ||
            response.data[0].finalScore === null ||
            response.data[0].finalScore === ""
          ) {
            setFinalScore("N/A");
          } else {
            setFinalScore(response.data[0].finalScore);
          }
          setFinalAdditionalScore(response.data[0].finalAdditionalScore);
        } catch (error) {
          console.error("Error fetching the form responses:", error);
        }
      }
    };

    fetchFormResponses();
  }, [selectedForm, location.state?.row.id]);

  useEffect(() => {
    const fetchFormDate = async () => {
      if (selectedForm) {
        const assessmentId = location.state?.row.id;
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_HOST}/forms/assessment/${assessmentId}`
          );
          const date = formatDate(response.data.createdAt);
          setDateOfAssignment(date);
        } catch (error) {
          console.error("Error fetching the form responses:", error);
        }
      }
    };

    fetchFormDate();
  }, [selectedForm, location.state?.row.id]);

  const handleFormChange = (e) => {
    setSelectedForm(e.target.value);
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const renderList = (obj) => {
    if (!obj || obj === undefined || obj === null || obj === "") {
      return <span>N/A</span>;
    }
    return (
      <ul>
        {Object.entries(obj).map(([key, value]) => (
          <li key={key}>
            <b>{key}:</b> {typeof value === "object" ? renderList(value) : value.toString()}
          </li>
        ))}
        <br />
      </ul>
    );
  };

  return (
    <>
      <div className="section">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-6">
              <div className="d-flex mb-3">
                <Link to="/completed-reports">
                  <i className="bx bx-chevron-left back-btn"></i>
                </Link>
                <h1>{`${row.firstName} ${row.lastName}`}</h1>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-6">
              <div className="d-flex mb-3">
                <b>
                  <label htmlFor="patientEmail">Patient Email: </label>
                </b>
                <div id="patientEmail" style={{ marginLeft: "25px" }}>
                  {row.email}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-6">
              <div className="d-flex mb-3">
                <b>
                  <label htmlFor="formSelect">Assessment Form: </label>
                </b>
                <select
                  id="formSelect"
                  value={selectedForm}
                  onChange={handleFormChange}
                  style={{ marginLeft: "25px" }}
                >
                  <option value="" disabled>
                    Select a form
                  </option>
                  {forms.map((form) => (
                    <option key={form.formId} value={form.formId}>
                      {form.formName}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-6">
              <div className="d-flex mb-3">
                <b>
                  <label htmlFor="assessmentDate">Date of Assignment: </label>
                </b>
                <div id="assessmentDate" style={{ marginLeft: "25px" }}>
                  {dateOfAssignment}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            {formResponses.length > 0 ? (
              <table className="table" style={{ borderCollapse: "collapse", width: "100%" }}>
                <thead>
                  <tr>
                    <th style={{ border: "1px solid black", padding: "8px" }}>Sr Number</th>
                    <th style={{ border: "1px solid black", padding: "8px" }}>Question Text</th>
                    <th style={{ border: "1px solid black", padding: "8px" }}>Answer Text</th>
                    <th style={{ border: "1px solid black", padding: "8px" }}>Answer Value</th>
                  </tr>
                </thead>
                <tbody>
                  {formResponses[0].questionsAndAnswers.map((qa, index) => (
                    <tr key={index}>
                      <td style={{ border: "1px solid black", padding: "8px" }}>{index + 1}</td>
                      <td style={{ border: "1px solid black", padding: "8px" }}>
                        {qa.questionText}
                      </td>
                      <td style={{ border: "1px solid black", padding: "8px" }}>{qa.answerText}</td>
                      <td style={{ border: "1px solid black", padding: "8px" }}>
                        {qa.answerValue}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <p>No responses available.</p>
            )}
          </div>
          <div className="row">
            <div className="col-12 col-md-6">
              <div className="d-flex mb-3">
                <b>
                  <label htmlFor="finalText">Final Text: </label>
                </b>
                <div id="finalText" style={{ marginLeft: "25px" }}>
                  {finalText}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-6">
              <div className="d-flex mb-3">
                <b>
                  <label htmlFor="finalScore">Final Score: </label>
                </b>
                <div id="finalScore" style={{ marginLeft: "25px" }}>
                  {finalScore}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-6">
              <div className="d-flex mb-3">
                <b>
                  <label htmlFor="finalAdditionalScore">Final Additional Score: </label>
                </b>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-6">
              <div className="d-flex mb-3">
                <div id="finalAdditionalScore">{renderList(finalAdditionalScore)}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CompletedReportDetails;
