import axios from 'axios';
import { toast } from 'react-hot-toast';

const API_URL = process.env.REACT_APP_API_HOST

export const addAssessment = (assessment, redirectToDashboard) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'ADD_ASSESSMENT_REQUEST' });

            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'x-auth-token': localStorage.getItem('xat')
                }
            }
            const { data } = await axios.post(`${API_URL}/assessment`, assessment, config);
            redirectToDashboard();
            toast.success('Assessment added successfully!');
            dispatch({ type: 'ADD_ASSESSMENT_SUCCESS', assessment: data });
        } catch (error) {
            toast.error(error.response.data.error);
            dispatch({ type: 'ADD_ASSESSMENT_FAILURE', error: error.message });
        }
    }
}

export const updateAssessment = (assessment, id, redirectToDashboard) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'UPDATE_ASSESSMENT_REQUEST' });

            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'x-auth-token': localStorage.getItem('xat')
                }
            }
            const { data } = await axios.put(`${API_URL}/assessment/${id}`, assessment, config);
            redirectToDashboard();
            toast.success('Assessment updated successfully!');
            dispatch({ type: 'UPDATE_ASSESSMENT_SUCCESS', assessment: data });
        } catch (error) {
            toast.error(error.response.data.error);
            dispatch({ type: 'UPDATE_ASSESSMENT_FAILURE', error: error.message });
        }
    }
}



export const getAssessments = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'GET_ASSESSMENTS_REQUEST' });

            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'x-auth-token': localStorage.getItem('xat')
                }
            }
            const { data } = await axios.get(`${API_URL}/assessment/psychologist`, config);
            dispatch({ type: 'GET_ASSESSMENTS_SUCCESS', assessments: data });
        } catch (error) {
            dispatch({ type: 'GET_ASSESSMENTS_FAILURE', error: error.message });
        }
    }
}


export const deleteAssessment = (id) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'DELETE_ASSESSMENT_REQUEST' });

            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'x-auth-token': localStorage.getItem('xat')
                }
            }
            await axios.delete(`${API_URL}/assessment/${id}`, config);
            toast.success('Assessment deleted successfully!');
            dispatch({ type: 'DELETE_ASSESSMENT_SUCCESS', id });
        } catch (error) {
            toast.error(error.response.data.message);
            dispatch({ type: 'DELETE_ASSESSMENT_FAILURE', error: error.message });
        }
    }
}


export const approveAndSend = (id, dat, redirectToPendingReports) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'APPROVE_SEND_REQUEST' });

            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'x-auth-token': localStorage.getItem('xat')
                }
            }
            const { data } = await axios.post(`${API_URL}/assessment/approve-send-sub-report/${id}`, dat, config);
            toast.success('Assessment sent successfully!');
            dispatch({ type: 'APPROVE_SEND_SUCCESS', assessment: data.assessment });
            redirectToPendingReports();
        } catch (error) {
            toast.error(error.response.data.error);
            console.log(error.response.data.assessment);
            dispatch({ type: 'APPROVE_SEND_FAILURE', assessment: error.response.data.assessment });
            redirectToPendingReports();
        }
    }
}

export const updateContent = (id, content) => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'UPDATE_CONTENT_REQUEST' });

            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'x-auth-token': localStorage.getItem('xat')
                }
            }
            const { data } = await axios.put(`${API_URL}/assessment/update-content/${id}`, content, config);
            toast.success('Assessment updated successfully!');
            dispatch({ type: 'UPDATE_CONTENT_SUCCESS', assessment: data });
        } catch (error) {
            toast.error(error.response.data.error);
            dispatch({ type: 'UPDATE_CONTENT_FAILURE', error: error.message });
        }
    }
}

export const resetTimeLimit = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'RESET_TIME_LIMIT_REQUEST' });

            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'x-auth-token': localStorage.getItem('xat')
                }
            }
            await axios.post(`${API_URL}/auth/reset-time-limit`, {}, config);
            dispatch({ type: 'RESET_TIME_LIMIT_SUCCESS' });
        } catch (error) {
            dispatch({ type: 'RESET_TIME_LIMIT_FAILURE', error: error.message });
        }
    }
}
