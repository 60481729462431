import React, { useEffect, useState } from "react";
import "../../styles/LoginForm.css";
import "../../styles/Assessment.css";
import { useSelector, useDispatch } from "react-redux";
import { approveAndSend, updateContent } from "../../app/actions/assessActions";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";

const ReviewReport = () => {
  const dispatch = useDispatch();
  const redirect = useNavigate();

  const { assessments } = useSelector((state) => state.Assess);
  const { user } = useSelector((state) => state.Auth);
  const API_URL = process.env.REACT_APP_API_HOST;

  const id = useParams().id;

  const [assessment, setAssessment] = useState({});
  const [assessContent, setAssessContent] = useState({});
  const [rows, setRows] = useState(10);
  const [longReport, setLongReport] = useState("short");

  useEffect(() => {
    const assess = assessments.find((assess) => assess._id === id);
    setAssessment(assess);
    setAssessContent(assess?.shortSummary);
  }, [assessments, id]);

  const removeUnderScore = (key) => {
    return key?.replace(/_/g, " ");
  };

  const handleLongShort = (e) => {
    const { checked } = e.target;
    console.log(checked);
    const text = document.getElementById("report-short-long");
    if (checked) {
      setAssessContent(assessment?.longSummary);
      console.log(assessContent, "long report");
      setRows(20);
      text.innerText = "Long Report";
      setLongReport("long");
    } else {
      setAssessContent(assessment?.shortSummary);
      console.log(assessContent, "short report");
      text.innerText = "Short Report";
      setRows(10);
      setLongReport("short");
    }
  };

  const [isGeneratingPDF, setIsGeneratingPDF] = useState(false);
  const [isApproving, setIsApproving] = useState(false);

  const downloadAssessmentReport = async () => {
    setIsGeneratingPDF(true);

    const content = `
            <html>
                <head>
                    <style>
                        body { font-family: Arial, sans-serif; }
                        h1 { text-align: center; }
                        p { margin: 0 0 10px; }
                        .heading-text { font-size: 18px; margin: 20px 0 5px;}
                    </style>
                </head>
                <body>
                    <h1>Psychological Assessment Report</h1>
                    <p>Name: ${assessment?.patientId?.firstName} ${ assessment?.patientId?.lastName}</p>
                    <p>Date of Birth: ${assessment?.userDetails?.yearOfBirth}</p>
                    <p>Age: ${new Date().getFullYear() - assessment?.userDetails?.yearOfBirth}</p>
                    <p>Assessed By: ${user?.firstName} ${user?.lastName}</p>
                    <p>Assessment Date: ${new Date(assessment?.updatedAt).toLocaleDateString()}</p>
                    ${Object.entries(assessContent).map(([key, value]) => 
                    `<p class="heading-text">
                      <b>${removeUnderScore(key)}</b>
                        </p> 
                        <p>${Array.isArray(value) ? value.join("<br/>") : value}</p>`).join("")}
                    <br>
                    ${!!user?.signature ? `<img src="${user?.signature}" alt="signature" style="width: 200px; height: 100px; margin: 15px 0 10px 0;">` : ""} 
                    ${!!user?.additional ? `<p>${user?.additional.replace(/\n/g, "<br>")}</p>` : ""}
                </body>
            </html>
        `;

    const options = {
      format: "A4",
      margin: {
        top: "0.5in",
        right: "0.5in",
        bottom: "0.5in",
        left: "0.5in",
      },
    };

    try {
      const response = await axios.post(
        `${API_URL}/generate-pdf`,
        { content, options },
        { responseType: "blob" }
      );
      const blob = new Blob([response.data], { type: "application/pdf" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "Assessment_Report.pdf";
      link.click();
    } catch (error) {
      console.error("Error generating PDF:", error);
    } finally {
      setIsGeneratingPDF(false);
    }
  };

  const redirectToPendingReports = () => {
    redirect("/pending-reports");
  };

  const approveSendReport = async () => {
    setIsApproving(true);
    const content = `
            <html>
                <head>
                    <style>
                        body { font-family: Arial, sans-serif; }
                        h1 { text-align: center; }
                        p { margin: 0 0 10px; }
                        .heading-text { font-size: 18px; margin: 20px 0 5px;}
                    </style>
                </head>
                <body>
                    <h1>Psychological Assessment Report</h1>
                    <p>Name: ${assessment?.patientId?.firstName} ${
      assessment?.patientId?.lastName
    }</p>
                    <p>Date of Birth: ${assessment?.userDetails?.yearOfBirth}</p>
                    <p>Age: ${new Date().getFullYear() - assessment?.userDetails?.yearOfBirth}</p>
                    <p>Assessed By: ${user?.firstName} ${user?.lastName}</p>
                    <p>Assessment Date: ${new Date(assessment?.updatedAt).toLocaleDateString()}</p>
                    ${Object.entries(assessContent)
                      .map(
                        ([key, value]) => `
                        <p class="heading-text"><b>${removeUnderScore(key)}</b></p> <p>  ${
                          Array.isArray(value) ? value.join("<br/>") : value
                        }</p>
                    `
                      )
                      .join("")}
                    <br>
                    ${
                      !!user?.signature
                        ? `<img src="${user?.signature}" alt="signature" style="width: 200px; height: 100px; margin: 15px 0 10px 0;">`
                        : ""
                    }
                    ${!!user?.additional ? `<p>${user?.additional.replace(/\n/g, "<br>")}</p>` : ""}
                </body>
            </html>
        `;

    const options = {
      format: "A4",
      margin: {
        top: "0.5in",
        right: "0.5in",
        bottom: "0.5in",
        left: "0.5in",
      },
    };

    try {
      dispatch(approveAndSend(id, { content, options, longReport }, redirectToPendingReports));
    } catch (error) {
      console.error("Error generating PDF:", error);
    } finally {
      setIsApproving(false);
    }
  };

  const editReport = (index) => {
    const content = document.getElementById(`content-${index}`);
    const text = content.innerText;
    const textarea = document.createElement("textarea");
    textarea.className = "form-control";
    textarea.style.height = `${rows * 20}px`;
    textarea.value = text;
    content.innerHTML = "";
    content.appendChild(textarea);

    const icon = document.getElementById(`icon-${index}`);

    const key = document.getElementById(`key-${index}`);

    key.classList.add("d-flex");
    key.classList.add("justify-content-between");

    const saveBtn = document.createElement("button");
    saveBtn.innerText = "Save";
    saveBtn.className = "save-btn";
    icon.replaceWith(saveBtn);
    saveBtn.addEventListener("click", () => {
      const newText = textarea.value;
      content.innerHTML = `<p className="report-content">${newText}</p>`;

      const newContent = { ...assessContent };
      newContent[Object.keys(newContent)[index]] = newText;
      setAssessContent(newContent);
      const formData = new FormData();
      formData.append("summary", JSON.stringify(newContent));
      formData.append("longReport", longReport);
      dispatch(updateContent(id, formData));
      window.location.reload();
    });

    saveBtn.addEventListener("click", () => {
      key.classList.remove("d-flex");
      key.classList.remove("justify-content-between");
      saveBtn.replaceWith(icon);
    });
  };

  return (
    <div className="section">
      <div className="container-fluid">
        <div className="d-flex justify-content-between mb-4">
          <div className="">
            <div className="d-flex">
              <Link to="/pending-reports">
                <i className="bx bx-chevron-left back-btn"></i>
              </Link>
              <span>
                <h1>
                  {assessment?.patientId?.firstName} {assessment?.patientId?.lastName}
                </h1>
              </span>
            </div>
          </div>
          <div className="">
            <button className="approve-btn mx-2a" onClick={approveSendReport}>
              {isApproving ? (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              ) : (
                "Approve and Send PDF"
              )}
            </button>
            <button className="download-btn mx-2" onClick={downloadAssessmentReport}>
              {isGeneratingPDF ? (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              ) : (
                "Download Report"
              )}
            </button>
          </div>
        </div>
        {(user?.planId === "assess-gold-monthly-psychologist" ||
          user?.planId === "assess-gold-annually-psychologist") && (
          <div className="d-flex justify-content-between">
            <div></div>
            <div className="d-flex align-items-center gap-2">
              <span id="report-short-long">Short Report</span>
              <span className="switch">
                <input id="switch-rounded" type="checkbox" onChange={handleLongShort} />
                <label htmlFor="switch-rounded"></label>
              </span>
            </div>
          </div>
        )}
      </div>
      <div className="container">
        <div className="mb-5">
          <h5 className="mb-3">
            <b>Name: </b>
            {assessment?.patientId?.firstName} {assessment?.patientId?.lastName}{" "}
          </h5>
          <h5 className="mb-3">
            <b>Year of Birth: </b>
            {assessment?.userDetails?.yearOfBirth}
          </h5>
          <h5 className="mb-3">
            <b>Age: </b>
            {new Date().getFullYear() - assessment?.userDetails?.yearOfBirth}
          </h5>
          <h5 className="mb-3">
            <b>Assessed By: </b>
            {user?.firstName} {user?.lastName}
          </h5>
          <h5 className="mb-3">
            <b>Assessment Date: </b>
            {new Date(assessment?.updatedAt).toLocaleDateString()}
          </h5>
        </div>

        {assessContent && (
          <div className="assessment">
            {Object.keys(assessContent)?.map((key, index) => {
              return (
                <div className="my-2" key={index}>
                  <div className="assessment-content-key mb-1" id={`key-${index}`}>
                    {removeUnderScore(key)}{" "}
                    <i
                      className="bx bx-edit-alt key-icon"
                      id={`icon-${index}`}
                      onClick={() => {
                        editReport(index);
                      }}
                    ></i>
                  </div>
                  <div className="assessment-content-value" id={`content-${index}`}>
                    <p className="report-content">
                      {typeof assessContent[key] === "object"
                        ? assessContent[key]?.map((item, index) => {
                            return (
                              <span key={index}>
                                {item}
                                <br />
                              </span>
                            );
                          })
                        : assessContent[key]}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default ReviewReport;
