import axios from 'axios';
import { toast } from 'react-hot-toast';

const API_URL = process.env.REACT_APP_API_HOST

export const getForms = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: 'GET_FORMS_REQUEST' });
            const { data } = await axios.get(`${API_URL}/forms`);
            dispatch({ type: 'GET_FORMS_SUCCESS', forms: data });
        } catch (error) {
            toast.error(error.response.data.message);
            dispatch({ type: 'GET_FORMS_FAILURE', error: error.message });
        }
    }
}