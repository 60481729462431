export const PLANS = {
  BRONZE: {
    NAME: "Bronze",
    MONTHLY_PRICE: 0,
    YEARLY_PRICE: 0,
    BULLET_POINTS: [
      "Account Setup",
      `Use upto 5 forms (limit to the optional forms)`,
      "Send / receive upto 5 forms per month",
    ],
  },

  SILVER: {
    NAME: "Silver",
    MONTHLY_PRICE: 99,
    YEARLY_PRICE: 999,
    BULLET_POINTS: [
      "Bronze features Plus",
      "Access all 50+ forms and limited invitations per month*",
      "Generate AI Reports (short version)",
      "Store Reports / Data for upto 3 months"
    ],
  },
  GOLD: {
    NAME: "Gold",
    MONTHLY_PRICE: 299,
    YEARLY_PRICE: 2999,
    BULLET_POINTS: [
      `Silver features Plus`,
      "Generate AI Reports (Short & Long version)",
      "Customize Reports (Add logo)",
      "Add CliniScripts Gold functionality + Unlimited invitations to users",
      "Store Reports / Data 1 year"
    ],
  }
};

export const PLANS_ARRAY = [PLANS.BRONZE, PLANS.SILVER, PLANS.GOLD];