const initialState = {
    loading: false,
    forms: [],
};

const Form = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_FORMS_REQUEST':
            return { ...state, loading: true };
        case 'GET_FORMS_SUCCESS':
            return { ...state, loading: false, forms: action.forms };
        case 'GET_FORMS_FAILURE':
            return { ...state, loading: false, error: action.error };
        default:
            return state;
    }
};

export default Form;